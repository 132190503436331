import React from "react";
import { cn } from "~/lib/utils";

export function FormLabel({ children }: { children: React.ReactNode }) {
  return <div className="md:col-span-2 col-span-12 mr-2 ">{children}</div>;
}
export function FormInput({ children }: { children: React.ReactNode }) {
  return (
    <div className="md:col-span-10 col-span-12 flex items-center">
      {children}
    </div>
  );
}

export function FormRowDepricated({
  label,
  input,
}: {
  label: React.ReactNode;
  input: React.ReactNode;
}) {
  return (
    <div className="grid grid-cols-12 my-2 ">
      <FormLabel>{label}</FormLabel>
      <FormInput>{input}</FormInput>
    </div>
  );
}

interface FormFieldProps {
  label: string;
  errorMessage?: string;
  input: React.ReactNode;
}

export function Form({ children }: { children: React.ReactNode }) {
  return (
    <div className="grid md:inline-grid w-full md:grid-cols-[auto,1fr] overflow-auto md:gap-y-2 ">
      {children}
    </div>
  );
}
export const FormField: React.FC<FormFieldProps> = ({
  label,
  errorMessage,
  input,
}) => {
  return (
    <>
      <div className="justify-self-start self-start">
        <span className="whitespace-nowrap font-medium mt-1">{label}</span>
      </div>
      <div className="mb-2 md:mb-0 md:ml-3 justify-self-start self-start">
        <div>{input}</div>
        <div className="text-destructive-foreground text-sm font-medium leading-4">
          {errorMessage}
        </div>
      </div>
    </>
  );
};

export function FormRow({
  children,
  className,
}: {
  children?: React.ReactNode;
  className?: string;
}) {
  return (
    <div className={cn("col-span-1 md:col-span-2", className)}>{children}</div>
  );
}
